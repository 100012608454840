<template>
  <v-row class="home">
    <v-col cols="12" class="pa-0 mt-3">
      <v-card class="panel-home" width="100%" :style="`margin-bottom:${setMarginBottom}`" :height="$vuetify.theme.dark ? 208 : 200">
        <v-img min-width="100%" :min-height="200" :src="require(`@/assets/product-outbound/background-panel-hello${$vuetify.theme.dark ? '--dark' : ''}.svg`)">
          <v-row class="fill-height ma-0" align="center">
            <span class="d-block pl-12 title-panel primary--text">¡Hola{{ $store.state.auth.userAccount.first_name ? ` ${$store.state.auth.userAccount.first_name}`: ''}}!
              <span class="d-inline-block text-h1 ml-3" style="margin-top: -20px; position: absolute">👋🏼</span>
            </span>
          </v-row>
        </v-img>
      </v-card>
      <v-col class="px-0 text-end">
        <v-row align="center" class="mt-n8 mb-n2" no-gutters>
          <v-spacer />
          <span class="subtitle-2 primary--text">Accesos directos</span>
          <!-- <v-btn @click="dialog=true" text color="primary" :ripple="false">
            <span class="font-weight-medium body-1">Editar accesos directos</span>
            <v-icon small class="ml-2">mdi-open-in-new</v-icon>
          </v-btn> -->
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-card class="px-5 py-5" height="135" min-width="260">
              <v-row class="fill-height ma-0" justify="start" align="center">
                <div>
                  <v-img class="ml-0" style="position: absolute" contain :height="44" :src="require(`@/assets/product-outbound/panel-icons/portal--icon.svg`)" />
                  <span class="d-block mt-16 pt-2 defaultGrey--text font-weight-bold" :class="$vuetify.breakpoint.width <= 1200 ? 'body-1' : 'subtitle-1'" style="line-height: normal">Portal del consumidor</span>
                </div>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="3" class="">
            <v-card class="px-5 py-5" height="135" min-width="260">
              <v-row class="fill-height ma-0" justify="start" align="center">
                <div>
                  <v-img class="ml-0" style="position: absolute" contain :height="44" :src="require(`@/assets/product-outbound/panel-icons/send-documents--icon.svg`)" />
                  <span class="d-block mt-16 pt-2 defaultGrey--text font-weight-bold" :class="$vuetify.breakpoint.width <= 1200 ? 'body-1' : 'subtitle-1'" style="line-height: normal">Envío de documentos</span>
                </div>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    list: [],
    listCL: [],
    listPE: ['Portal del consumidor', 'Envío de documentos'],
    currentShortcutsList: [],
    shortcuts: [
    {
        icon: 'documents',
        title: 'Emisión',
        description: '',
        countries: ['CL', 'PE'],
        show: false,
        children: [
          {
            icon: 'documents',
            title: 'Documentos emitidos',
            route: 'DocumentsList',
            countries: ['CL', 'PE'],
            show: false
          },
          {
            icon: 'documents',
            title: 'Clientes',
            route: 'CustomerListCreate',
            countries: ['CL', 'PE'],
            show: false
          }
        ]
      }
    //   {
    //     icon: 'documents',
    //     title: 'Emisión',
    //     description: '',
    //     countries: ['CL', 'PE'],
    //     show: false,
    //     children: [
    //       {
    //         icon: 'documents',
    //         title: 'Documentos emitidos',
    //         route: 'DocumentsList',
    //         countries: ['CL', 'PE'],
    //         show: false
    //       },
    //       {
    //         icon: 'documents',
    //         title: 'Clientes',
    //         route: 'CustomerListCreate',
    //         countries: ['CL', 'PE'],
    //         show: false
    //       }
    //     ]
    //   },
    //   {
    //     icon: 'purchases',
    //     title: 'Recepción',
    //     description: '',
    //     countries: ['CL'],
    //     show: false,
    //     children: [
    //       {
    //         icon: 'purchases',
    //         title: 'Documentos recibidos',
    //         route: 'PurchaseList',
    //         countries: ['CL', 'PE'],
    //         show: false
    //       },
    //       {
    //         icon: 'purchases',
    //         title: 'Automatización',
    //         route: 'AutomationList',
    //         countries: ['CL', 'PE'],
    //         show: false
    //       },
    //       {
    //         icon: 'purchases',
    //         title: 'Proveedores',
    //         route: 'SupplierListCreate',
    //         countries: ['CL', 'PE'],
    //         show: false
    //       },
    //       {
    //         icon: 'purchases',
    //         title: 'Segmentos',
    //         route: 'SegmentsList',
    //         show: false,
    //         countries: ['CL', 'PE']
    //       }
    //     ]
    //   },
    //   {
    //     icon: 'folios',
    //     title: 'Folios',
    //     description: '',
    //     countries: ['CL', 'PE'],
    //     show: false,
    //     children: [
    //       {
    //         icon: 'folios',
    //         title: 'Disponibilidad',
    //         route: 'FoliosListCreate',
    //         countries: ['CL'],
    //         show: false
    //       }
    //     ]
    //   },
    //   {
    //     icon: 'reports',
    //     title: 'Reportes',
    //     description: '',
    //     countries: ['CL', 'PE'],
    //     show: false,
    //     children: [
    //       {
    //         icon: 'reports',
    //         title: 'Registro de ventas',
    //         route: 'DocumentSummary',
    //         countries: ['CL', 'PE']
    //       }
    //     ]
    //   },
    //   {
    //     icon: 'account',
    //     title: 'Configuración',
    //     description: '',
    //     countries: ['CL', 'PE'],
    //     show: false,
    //     children: [
    //       {
    //         icon: 'account',
    //         title: 'Organización',
    //         route: 'AccountBasic',
    //         countries: ['CL', 'PE'],
    //         show: false
    //       },
    //       {
    //         icon: 'sii',
    //         title: 'Sunat',
    //         route: 'IrsBuiltInSettings',
    //         countries: ['CL', 'PE'],
    //         show: false
    //       },
    //       {
    //         icon: 'notifications',
    //         title: 'Notificaciones',
    //         route: 'AccountNotifications',
    //         countries: ['CL', 'PE'],
    //         show: false
    //       }
    //     ]
    //   }
    ],
    min: 0,
    max: 0,
    backupList: []
  }),
  computed: {
    setMarginBottom () {
      if (this.$vuetify.breakpoint.width < 1400) return '4%'
      else if (this.$vuetify.breakpoint.width < 1500) return '5%'
      else if (this.$vuetify.breakpoint.width < 1700) return '6.5%'
      else if (this.$vuetify.breakpoint.width < 1900) return '7%'
      return '9.5%'
    },
    shortcutsByCountry () {
      return this.shortcuts.filter((item) => item.children.some((children) => this.list.includes(children.title) || children.countries.includes(this.$store.state.auth.account.country ?? 'PE')))
    }
  },
  created () {
    this.list = this.$store.state.auth.account.country === 'CL' ? [...this.listCL] : [...this.listPE]
  },
  mounted () {
    // this.setShortCuts()
  },
  watch: {
    '$store.state.auth.account.id' (val) {
      this.list = val === 'CL' ? this.listCL : this.listPE
    }
  },
  methods: {
    /* eslint-disable */
    getShortcuts (children) {
      if (this.list.length <= 4) {
        if (this.list.includes(children.title)) {
          this.min += 1
          if (this.min === 1) {
            this.$dialog.message.error('Debes tener al menos 4 accesos directos para acceder rápidamente a tus funciones favoritas.')
            setTimeout(() => {
              this.min = 0
            }, 3000)
          }
          this.$nextTick(() => {
            children.show = true
          })
        } else {
          this.list.push(children.title)
          this.$nextTick(() => {
            children.show = true
          })
        }
      } else if (this.list.length >= 8) {
        if (this.list.includes(children.title)) {
          this.list.splice(this.list?.indexOf(children.title), 1)
        } else {
          this.max += 1
          if (this.max === 1) {
            this.$dialog.message.error('Debes tener hasta 8 accesos directos para acceder rápidamente a tus funciones favoritas.')
            setTimeout(() => {
              this.max = 0
            }, 3000)
          }
          this.$nextTick(() => {
            children.show = false
          })
        }
      } else {
        if (!this.list.includes(children.title)) {
          this.list.push(children.title)
        } else {
          this.list.splice(this.list?.indexOf(children.title), 1)
        }
      }
    },
    saveShortCuts () {
      let recovery = JSON.parse(localStorage.getItem('shortcuts-ax-demo')) ?? []
      const index = recovery?.findIndex(({account_id}) => account_id === this.$store.state.auth.account.id)
      if (index !== -1) {
        recovery[index].shortcuts = this.list
      } else {
        recovery.push({ account_id: this.$store.state.auth.account.id, shortcuts: this.list })
      }

      localStorage.setItem('shortcuts-ax-demo', JSON.stringify(recovery))
      this.setShortCuts()
      this.dialog = false
    },
    setShortCuts () {
      const recovery = JSON.parse(localStorage.getItem('shortcuts-ax-demo'))
      const found = recovery?.find(({account_id}) => account_id === this.$store.state.auth.account.id)
      this.list = found?.shortcuts ?? this.list
      let array = []
      this.shortcuts?.forEach((item) => {
        item.children.forEach((children) => {
          if (this.list.includes(children.title) && children.countries.includes(this.$store.state.auth.account.country)) {
            children.show = true
            array.push(children)
            this.currentShortcutsList = [{ ...item, children: array}]
          }
        })
      })
      this.backupList = [...this.list]
    },
    clear () {
      this.list = [...this.backupList]
      this.shortcuts.forEach((item) => {
        item.children.forEach((children) => {
          children.show = this.list.includes(children.title)
        })
      })
      this.dialog = false
    }
  }
}
</script>